<script setup>
import { watch } from 'vue'
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/vue/24/solid'
import IconSpinner from '@/components/IconSpinner.vue'
const props = defineProps({
  variant: {
    type: String,
    default: 'primary',
    validator: (value) =>
      ['primary', 'secondary', 'tertiary', 'quaternary', 'danger'].includes(value)
  },
  text: {
    type: String,
    default: ''
  },
  iconLeft: {
    type: Boolean,
    default: false
  },
  iconRight: {
    type: Boolean,
    default: false
  },
  disabled: {
    type: Boolean,
    default: false
  },
  showSpinner: {
    type: Boolean,
    default: false
  }
})

const variantClasses = {
  primary:
    ' bg-mainGreen-700 text-white hover:bg-mainGreen-600 focus:ring-4 focus:ring-mainGreen-200 w-fit px-8 py-3',
  secondary:
    'bg-white text-black border border-gray-300 hover:bg-gray-100 focus:ring-4 focus:ring-gray-200 w-fit px-8 py-3',
  tertiary:
    'border text-white hover:bg-gray-100/20 focus:ring-4 focus:ring-gray-300 w-fit px-8 py-3',
  quaternary:
    'bg-white text-black border border-gray-300 hover:bg-gray-100 focus:ring-4 focus:ring-gray-200 w-fit px-8 py-3',
  text: 'bg-transparent text-white underline hover:bg-mainGrey-700 hover:no-underline focus:ring-4 focus:ring-mainGrey-700 w-fit px-1 py-1 ',
  danger:
    'bg-mainRed-500 text-white hover:bg-mainRed-600 focus:ring-4 focus:ring-mainRed-300 w-fit px-8 py-3'
}

defineEmits(['click'])
</script>

<template>
  <button
    :class="[
      ' text-sm rounded-md transition duration-300 ease-in-out flex items-center self-center',
      variantClasses[variant],
      { '!bg-mainGrey-400 cursor-not-allowed': disabled }
    ]"
    :disabled="props.disabled"
    @click="$emit('click')"
  >
    <IconSpinner :showSpinner="props.showSpinner" />
    <span v-if="props.iconLeft"><ChevronLeftIcon class="mr-2 -ml-1 size-5" /> </span>
    <slot>{{ props.text }}</slot>
    <span v-if="props.iconRight"><ChevronRightIcon class="ml-2 -mr-1 size-5" /> </span>
  </button>
</template>

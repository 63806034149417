import './assets/main.css'
import { createApp } from 'vue'
import { createPinia } from 'pinia'
import { createI18n } from 'vue-i18n'
import { createRouter } from './router'
import App from './App.vue'
import locale from '@/lib/locale'
// import * as Sentry from '@sentry/vue'

const app = createApp(App)
const i18n = createI18n(locale)
const pinia = createPinia()
const router = createRouter(pinia)

// Sentry.init({
//   app,
//   dsn: 'https://abcc21ddec19b2826472016da79ba94b@o4508057814827008.ingest.de.sentry.io/4508057818628176',
//   integrations: []
// })

app.use(pinia)
app.use(i18n)
app.use(router)
app.mount('#app')
